/* eslint-disable no-case-declarations */
import MultiselectInput from "./MultiselectInput";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";
import { getInitialConfigurationValue } from "../../lib/siteHelpers";
import ToggleSelect from "./ToggleSelect";

type ItemInputProps = {
  configurationValue: string | string[] | boolean | number | null;
  dataType: ConfigurationItemDataType;
  readOnly?: boolean;
  isDisabled?: boolean;
  required: boolean;
  validationRules: ValidationRulesType | null;
  onInputChange?: (value: string | string[] | boolean) => void;
  isPartOfPendingChangesList?: boolean;
  setValidationError?: (customValidationError: string) => void;
  isInNewSiteCreatingMode?: boolean;
  inputWidth?: number;
  errorMessage?: string;
};

function ItemInput(props: ItemInputProps) {
  const { configurationValue, dataType, validationRules } = props;

  const formatJSONDataTypeItem = (
    inputValue: Record<string, unknown> | string
  ) => {
    const jsonDataToDisplay =
      Object.keys(inputValue).length > 0
        ? JSON.stringify(inputValue, null, 2)
        : "";

    return (
      <TextInput
        {...props}
        inputValue={
          typeof inputValue === "object" ? jsonDataToDisplay : inputValue
        }
      />
    );
  };
  const defaultConfigurationValue = getInitialConfigurationValue(
    dataType,
    validationRules
  );
  const inputValue = configurationValue || defaultConfigurationValue;

  const getDataTypeForItemInput = () => {
    switch (dataType) {
      case "boolean":
      case "bool":
        if (["boolean", "string"].includes(typeof inputValue)) {
          return (
            <ToggleSelect
              {...props}
              value={inputValue.toString().toLowerCase()}
            />
          );
        }
        return null;
      case "text":
      case "guid":
      case "url":
        return (
          <TextInput
            {...props}
            inputValue={inputValue}
            textLengthLimit={validationRules?.textLengthLimit}
          />
        );
      case "jsonb":
      case "json":
        return formatJSONDataTypeItem(inputValue);

      case "select":
      case "text[]":
      case "int[]":
      case "ordered_list":
        const selectLabels = validationRules?.allowedValues || [];

        return (
          <SelectInput
            {...props}
            value={inputValue}
            selectValues={selectLabels}
          />
        );

      case "int":
        return (
          <TextInput
            {...props}
            inputValue={inputValue}
            min={validationRules?.min}
            max={validationRules?.max}
          />
        );

      case "multiselect":
        const multiSelectLabels = validationRules?.allowedValues || [];
        if (!Array.isArray(inputValue)) return;

        return (
          <MultiselectInput
            {...props}
            value={inputValue}
            multiselectValues={multiSelectLabels}
          />
        );
    }
  };
  return getDataTypeForItemInput();
}

export default ItemInput;
