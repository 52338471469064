import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  DescriptionTooltip,
  ItemInputContainer
} from "../site/ConfigurationItem";
import ItemInput from "../input/ItemInput";
import { DuplicateSiteRequiredType } from "./DuplicateSiteContent";
import usePrevious from "../../hooks/usePrevious";
import { tabletWidth } from "../../lib/theme";
import useValidationRulesPerEnvironment from "../../hooks/useValidationRulesPerEnvironment";
import { useGetEnvironmentsListQuery } from "../../redux/api/environments";
import { Stack } from "@mui/material";
import EnvironmentTagButton, {
  EnvironmentTagsContainer
} from "../site/EnvironmentTagButton";

type RequieredSiteFieldsProps = {
  configurationItem: DuplicateSiteRequiredType;
  updateConfigItemCurrentValue: (
    configurationItemId: string,
    currentValue: string | string[] | boolean | number | null,
    currentValueEnvId?: string
  ) => void;
  validationErrors: {
    currentValueErrorMessage: string;
    currentValuesPerEnvValidationErrors: {
      [envId in string]: string;
    };
  };
  setValidationError: (
    errorMessage: string,
    configItemId: string,
    uniquePerEnvironment?: boolean,
    currentValueEnvId?: string
  ) => void;
  selectedEnvironmentsList: string[];
};

const MainContainer = styled(Stack)(() => ({
  alignItems: "center",
  flexWrap: "wrap",
  alignSelf: "flex-end",
  flexDirection: "row",
  gap: "10px"
}));

export const ConfigurationItemName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: {
    uniquePerEnvironment?: boolean;
  };
}>(({ extraProps, theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  color: theme.palette.text.secondary,
  position: "relative",
  alignSelf: extraProps?.uniquePerEnvironment ? "flex-start" : "center",
  top: extraProps?.uniquePerEnvironment ? "20px" : "0px"
}));

const RequieredSiteFields = (props: RequieredSiteFieldsProps) => {
  const {
    configurationItem,
    updateConfigItemCurrentValue,
    validationErrors,
    setValidationError,
    selectedEnvironmentsList
  } = props;
  const {
    dataType,
    name: configurationName,
    configurationItemId,
    currentValue,
    uniquePerEnvironment,
    currentValuesPerEnv,
    validationRules,
    description
  } = configurationItem;
  const previousUniquerPerEnvValue = usePrevious(currentValuesPerEnv);

  const { data } = useGetEnvironmentsListQuery(undefined);
  const environmentsData = (data || {}) as EnvironmentsListType;

  const { environmentsDetailsById = {} }: EnvironmentsListType =
    environmentsData;

  React.useEffect(() => {
    if (!uniquePerEnvironment) setValidationError("", configurationItemId);
  }, [currentValue]);

  React.useEffect(() => {
    if (uniquePerEnvironment && currentValuesPerEnv) {
      if (
        Object.values(
          validationErrors.currentValuesPerEnvValidationErrors
        )[0] === "URL values must be unique per environment."
      ) {
        Object.keys(validationErrors.currentValuesPerEnvValidationErrors).map(
          (envId) => {
            setValidationError(
              "",
              configurationItemId,
              uniquePerEnvironment,
              envId
            );
          }
        );
      } else {
        Object.keys(currentValuesPerEnv).forEach((envId) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const prevValueForEnv = previousUniquerPerEnvValue?.[envId] || "";
          const currentValueForEnv = currentValuesPerEnv?.[envId] || "";
          if (prevValueForEnv !== currentValueForEnv) {
            setValidationError(
              "",
              configurationItemId,
              uniquePerEnvironment,
              envId
            );
          }
        });
      }
    }
  }, [currentValuesPerEnv]);

  const { validationRulesPerEnvironment } = useValidationRulesPerEnvironment(
    validationRules,
    environmentsDetailsById
  );

  const isTabletWidth = useMediaQuery(tabletWidth);
  const inputWidth = isTabletWidth ? 180 : 230;

  return (
    <MainContainer data-testid="duplicate-modal-configuration-item-row">
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        gap={2}
        alignSelf={"start"}
        mt={0.5}
      >
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "500",
            color: "gray"
          }}
          aria-label="duplicate-modal-configuration-name"
        >
          {configurationName}
        </Typography>
        {!!description.length && (
          <DescriptionTooltip
            description={description}
            additionalStyling={{ alignSelf: "flex-start", top: "3px" }}
          />
        )}
      </Stack>
      <Stack flexDirection="column">
        {Object.keys(currentValuesPerEnv).map((currentEnvId: string, index) => {
          if (!uniquePerEnvironment && index >= 1) return;

          const currentValidationRules = validationRulesPerEnvironment[
            currentEnvId
          ]
            ? validationRulesPerEnvironment[currentEnvId]
            : validationRules[0];
          const validationRule =
            currentValidationRules?.validationRules || null;
          const isRequired = currentValidationRules?.isRequired || false;

          const valueToEdit =
            uniquePerEnvironment && !!currentValuesPerEnv
              ? currentValuesPerEnv[currentEnvId]
              : currentValue;
          const validationErrorMessage = uniquePerEnvironment
            ? validationErrors.currentValuesPerEnvValidationErrors[currentEnvId]
            : validationErrors.currentValueErrorMessage;

          return (
            <ItemInputContainer
              key={`duplicate-modal-configuration-item-${configurationItemId}${index}`}
              sx={{
                justifyContent: "flex-end",
                marginBottom:
                  uniquePerEnvironment && selectedEnvironmentsList?.length > 1
                    ? "15px"
                    : "5px"
              }}
            >
              <Stack flexDirection="column">
                <ItemInput
                  configurationValue={valueToEdit}
                  dataType={dataType}
                  required={isRequired}
                  validationRules={validationRule}
                  onInputChange={(newConfigItemValue) => {
                    updateConfigItemCurrentValue(
                      configurationItemId,
                      newConfigItemValue,
                      currentEnvId
                    );
                  }}
                  errorMessage={validationErrorMessage}
                  setValidationError={(message) =>
                    setValidationError(
                      message,
                      configurationItemId,
                      uniquePerEnvironment,
                      currentEnvId
                    )
                  }
                  inputWidth={inputWidth}
                />
              </Stack>
              <EnvironmentTagsContainer
                sx={{
                  padding: "0px 10px",
                  alignSelf: "baseline",
                  width: 260
                }}
                data-testid="env-tags-container"
              >
                {selectedEnvironmentsList.map((envId) => {
                  const envName =
                    environmentsDetailsById[envId]?.environmentName ||
                    "Staging";

                  if (
                    (uniquePerEnvironment && envId === currentEnvId) ||
                    !uniquePerEnvironment
                  )
                    return (
                      <EnvironmentTagButton
                        key={`required-field-env-label-${envName}`}
                        envName={envName}
                        selected
                        readonly={true}
                      />
                    );
                  return <></>;
                })}
              </EnvironmentTagsContainer>
            </ItemInputContainer>
          );
        })}
      </Stack>
    </MainContainer>
  );
};

export default RequieredSiteFields;
