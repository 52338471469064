import { useSelector } from "react-redux";

import MainSiteContainer from "../components/site/MainSiteContainer";
import { getCurrentOpenSiteView } from "../redux/selectors/siteSelectors";
import AuditLog from "../components/auditLog/AuditLog";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch } from "../store";
import { useLazyGetSitesListPreviewQuery } from "../redux/api/site";
import { getSelectedDrawersData } from "../redux/selectors/drawerSelectors";
import { navigateToRecentSite } from "../components/drawer/mainCustomDrawerSlice";

function SiteView() {
  const dispatch = useAppDispatch();
  const [getSiteLists] = useLazyGetSitesListPreviewQuery(undefined);
  const selectedDrawersData = useSelector(getSelectedDrawersData);
  const openCurrentSiteView = useSelector(getCurrentOpenSiteView);
  const navigate = useNavigate();
  const { siteId } = useParams<{
    siteId: string;
  }>();
  const renderSiteViewPageContent = () => {
    switch (openCurrentSiteView) {
      case "SiteDetails":
        return <MainSiteContainer />;
      case "AuditLog":
        return <AuditLog />;
      default:
        return <></>;
    }
  };
  useEffect(() => {
    if (siteId && !selectedDrawersData.site?.id) {
      void (async () => {
        const data = await getSiteLists(undefined).unwrap();
        const siteDetailsPreview = data.find(
          (siteData) => siteData.siteId === siteId
        );
        dispatch(
          navigateToRecentSite({
            clientId: siteDetailsPreview?.clientId,
            clientName: siteDetailsPreview?.clientName,
            siteId,
            siteName: siteDetailsPreview?.siteName,
            siteNavigationOnly: true
          })
        );
      })();
    }
  }, [siteId]);

  useEffect(() => {
    return () => {
      navigate("/");
    };
  }, [navigate]);

  return <>{renderSiteViewPageContent()}</>;
}

export default SiteView;
