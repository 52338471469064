import { Components, Theme } from "@mui/material";
import { mainColours } from "./colors";

export const muiListItemButton: Components<Omit<Theme, "components">> = {
  MuiListItemButton: {
    defaultProps: {
      disableRipple: true,
      disableTouchRipple: true
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        borderRadius: "6px",
        color: theme.palette.text.secondary,
        "&:hover": {
          backgroundColor: mainColours.background.hover
        },
        ...(ownerState?.selected && {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main
        })
      })
    }
  }
};
