import { useGetPartnersListQuery } from "../../redux/api/partner";
import { SelectedSingleDrawerDataType } from "./mainCustomDrawerSlice";

import CustomDrawerContent from "./CustomDrawerContent";
import ErrorPanel from "../ErrorPanel";

type PartnerDrawerContentProps = {
  onDrawerMenuBtnClick: (
    selectedDrawerOption: SelectedSingleDrawerDataType,
    parentDetails?: SelectedSingleDrawerDataType | null
  ) => void;
  drawerType: DrawerType;
};

function PartnerDrawerContent(props: PartnerDrawerContentProps) {
  const {
    data = [],
    isFetching,
    isError,
    refetch
  } = useGetPartnersListQuery(undefined, {
    refetchOnMountOrArgChange: true
  });

  if (isError)
    return (
      <ErrorPanel
        errorMessage="Failed to retrieve partners list."
        size="small"
        sx={{
          textAlign: "center"
        }}
        onRetryCb={async () => {
          await refetch();
        }}
      />
    );

  return (
    <CustomDrawerContent
      {...props}
      isDataLoading={isFetching}
      drawerContentData={data as unknown as CustomDrawerContentType[]}
    />
  );
}

export default PartnerDrawerContent;
