import { Components, Theme } from "@mui/material";

import CheckboxIcon from "@locaisolutions/icons/dist/icons24px/Checkmark24Px";
import { basicColours, mainColours } from "./colors";

export const muiCheckBox: Components<Omit<Theme, "components">> = {
  MuiCheckbox: {
    defaultProps: {
      icon: <CheckboxIcon fill={basicColours.mono.white} />,
      checkedIcon: <CheckboxIcon fill={basicColours.mono.white} />
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        return {
          borderRadius: "3px",
          height: 23,
          width: 23,
          padding: "2px",
          backgroundColor: "transparent",
          border: `2px solid ${theme.palette.divider}`,
          "&:hover": {
            borderColor: basicColours.mono.white[400],
            backgroundColor: "transparent"
          },
          ...(ownerState.checked && {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
              borderColor: theme.palette.primary.dark
            }
          }),
          ...(ownerState.disabled && {
            cursor: "not-allowed",
            opacity: 0.5,
            borderColor: mainColours.border.disabled
          })
        };
      }
    }
  }
};
