import SelectInput from "./SelectInput";

type ToggleSelectProps = {
  value: string;
  readOnly?: boolean;
  isDisabled?: boolean;
  onInputChange?: (value: string | string[]) => void;
  isPartOfPendingChangesList?: boolean;
  inputWidth?: number;
  errorMessage?: string;
};

const toggleSelectValues = {
  true: "Enabled",
  false: "Disabled",
  Enabled: "true",
  Disabled: "false"
};

const ToggleSelect = (props: ToggleSelectProps) => {
  const { onInputChange, value } = props;
  return (
    <SelectInput
      {...props}
      selectValues={["Enabled", "Disabled"]}
      value={toggleSelectValues[value as "true" | "false"]}
      onInputChange={(value) => {
        if (onInputChange) {
          onInputChange(toggleSelectValues[value as "Enabled" | "Disabled"]);
        }
      }}
    />
  );
};
export default ToggleSelect;
