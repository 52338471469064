/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import IconButton from "@mui/material/IconButton";

import EditIcon from "@locaisolutions/icons/dist/icons24px/Edit24Px";
import CloseCircleIcon from "@locaisolutions/icons/dist/icons24px/CloseCircle24Px";
import AttentionIcon from "@locaisolutions/icons/dist/icons24px/Info24Px";

import ItemInput from "../input/ItemInput";
import { SelectedConfigurationItemType } from "./ConfigurationItems";
import SelectedConfigItem from "./SelectedConfigItem";
import useValidationRulesPerEnvironment from "../../hooks/useValidationRulesPerEnvironment";
import { generateUUID } from "../../lib/helpers";
import { useGetEnvironmentsListQuery } from "../../redux/api/environments";
import EnvironmentTagButton, {
  EnvironmentTagsContainer
} from "./EnvironmentTagButton";
import CopyToClipboardButton from "../input/CopyBtn";

export const ItemInputContainer = styled(Stack)(() => ({
  flexDirection: "row",
  justifyContent: "center",
  alignSelf: "baseline",
  gap: "10px",
  flexWrap: "wrap"
}));

export const ItemValuesContainer = styled(Stack)(() => ({
  flexDirection: "column",
  gap: 12
}));

const MainContainer = styled(Stack)(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  margin: "0px 10px",
  justifyItems: "stretch",
  flexWrap: "wrap"
}));

const ItemValueNameContainer = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 40px",
  marginTop: "8px",
  gap: "5px",
  width: "200px"
}));

export const DescriptionTooltip = (props: {
  additionalStyling?: unknown;
  description: string;
}) => {
  return (
    <Tooltip
      title={props.description}
      aria-label="configuration-item-description"
      placement="top"
    >
      <span
        style={{
          position: "relative",
          ...(props?.additionalStyling ? props.additionalStyling : {})
        }}
      >
        <AttentionIcon height={20} width={20} />
      </span>
    </Tooltip>
  );
};
const EditCloseButton = (props: {
  onClickBtn: () => void;
  isConfigItemSelected: boolean;
}) => {
  const { onClickBtn, isConfigItemSelected } = props;
  return (
    <IconButton
      sx={
        !isConfigItemSelected
          ? {
              height: 42,
              width: 42,
              borderRadius: "6px",
              backgroundColor: "secondary.dark",
              ":hover": { backgroundColor: "secondary.light" },
              "& .MuiTouchRipple-child": { borderRadius: "inherit" }
            }
          : {
              padding: 0,
              mt: "3px"
            }
      }
      aria-label="edit-input-btn"
      disableRipple
      onClick={onClickBtn}
      data-testid={
        !isConfigItemSelected ? "edit-input-btn" : "close-edit-mode-btn"
      }
    >
      {!isConfigItemSelected ? (
        <EditIcon height={30} width={30} />
      ) : (
        <CloseCircleIcon height={30} width={30} />
      )}
    </IconButton>
  );
};

type ConfigurationItemProps = {
  selectedSectionItem: ConfigurationItemType;
  selectedConfigurationItem: SelectedConfigurationItemType;
  saveSelectedConfigurationItemChanges: (
    pendingChange: PendingChangeType
  ) => void;
  clearSelectedConfigItem: () => void;
  updateSelectedConfigurationItem: (
    configurationItem: SelectedConfigurationItemType
  ) => void;
  isPartOfPendingChangesList?: boolean;
  isInNewSiteCreatingMode?: boolean;
};

function ConfigurationItem(props: ConfigurationItemProps) {
  const {
    selectedSectionItem,
    selectedConfigurationItem,
    updateSelectedConfigurationItem,
    clearSelectedConfigItem,
    isPartOfPendingChangesList,
    isInNewSiteCreatingMode
  } = props;
  const {
    name,
    description,
    dataType,
    configurationItemId,
    configurationValues,
    validationRules
  } = selectedSectionItem;

  const { data } = useGetEnvironmentsListQuery(undefined);
  const environmentsData = (data || {}) as EnvironmentsListType;

  const { environmentsDetailsById = {} }: EnvironmentsListType =
    environmentsData;

  const { validationRulesPerEnvironment, allAvailableEnvironmentNames } =
    useValidationRulesPerEnvironment(validationRules, environmentsDetailsById);

  const isConfigItemSelected =
    selectedConfigurationItem.configurationItemId === configurationItemId;
  const isJSONType = ["json", "jsonb"].includes(dataType);

  const onEditBtnClick = (item: ConfigurationItemType) => {
    updateSelectedConfigurationItem({
      configurationName: name,
      configurationItemId,
      originalValues: configurationValues,
      currentSelectedConfigValue: item
    });
  };

  useEffect(() => {
    clearSelectedConfigItem();
  }, []);

  return (
    <MainContainer data-testid="cofig-item-container">
      <ItemValueNameContainer>
        <Typography
          sx={{
            fontSize: "0.8rem"
          }}
        >
          {name}
        </Typography>
        {!!description.length && (
          <DescriptionTooltip description={description} />
        )}
      </ItemValueNameContainer>
      <Stack flexDirection="row" gap={1}>
        <Box width={42}>
          {!isJSONType && !isPartOfPendingChangesList ? (
            <EditCloseButton
              isConfigItemSelected={isConfigItemSelected}
              onClickBtn={() => {
                if (isConfigItemSelected) {
                  clearSelectedConfigItem();
                } else {
                  onEditBtnClick(selectedSectionItem);
                }
              }}
            />
          ) : (
            <></>
          )}
        </Box>
        <Stack flexDirection="column" gap={2}>
          {configurationValues.map((item: ConfigurationValue, index) => {
            const { environmentIds, configurationValue } = item;
            const currentValidationRules =
              validationRulesPerEnvironment[environmentIds[0]];

            const validationRule =
              currentValidationRules?.validationRules || null;
            const isRequired = currentValidationRules?.isRequired || false;

            const shouldRenderCopyBtn =
              isConfigItemSelected &&
              ["text", "url", "json", "jsonb"].includes(dataType) &&
              typeof configurationValue === "string";

            return (
              <Stack
                flexDirection="row"
                gap={1}
                key={`environment-values-item-${configurationItemId}-${index}`}
              >
                <Box
                  sx={{
                    margin: "8px 0px 0px 5px",
                    height: 30,
                    width: 30
                  }}
                >
                  {shouldRenderCopyBtn ? (
                    <CopyToClipboardButton value={configurationValue} />
                  ) : (
                    <></>
                  )}
                </Box>

                <Stack
                  flexDirection="row"
                  gap={1}
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  <ItemInput
                    configurationValue={configurationValue}
                    dataType={dataType}
                    required={isRequired}
                    readOnly
                    validationRules={validationRule}
                    isPartOfPendingChangesList={isPartOfPendingChangesList}
                    isInNewSiteCreatingMode={isInNewSiteCreatingMode}
                  />
                  <EnvironmentTagsContainer
                    minWidth={260}
                    sx={{
                      justifyContent: "center"
                    }}
                  >
                    {allAvailableEnvironmentNames.map((envName) => {
                      if (
                        environmentIds.find(
                          (envId: string) =>
                            envName ===
                            environmentsDetailsById?.[envId]?.environmentName
                        )
                      )
                        return (
                          <EnvironmentTagButton
                            key={`env-${envName || generateUUID()}`}
                            envName={envName}
                            selected
                            readonly
                          />
                        );
                      return <></>;
                    })}
                  </EnvironmentTagsContainer>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Stack>

      {isConfigItemSelected && (
        <SelectedConfigItem
          {...props}
          currentConfigurationItem={selectedSectionItem}
        />
      )}
    </MainContainer>
  );
}

export default ConfigurationItem;
