import { Components, Theme } from "@mui/material";
import { basicColours, mainColours } from "./colors";

export const muiButton: Components<Omit<Theme, "components">> = {
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      variant: "contained"
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        fontWeight: 500,
        fontSize: "14px",
        borderRadius: "6px",
        padding: "10px",
        gap: "10px",
        minHeight: 32,
        minWidth: 64,
        textTransform: "none",
        cursor: "pointer",
        "&.Mui-disabled": {
          pointerEvents: "unset",
          cursor: "not-allowed"
        },
        "&.MuiButton-containedPrimary.Mui-disabled": {
          background: basicColours.blue[100],
          color: basicColours.mono.white
        },
        "&:focus-visible": {
          outline: `4px solid ${theme.palette.primary.dark}`
        },
        ...(ownerState["aria-readonly"] && {
          cursor: "default"
        })
      }),
      contained: {
        "&:focus-visible": {
          outlineOffset: "2px"
        }
      }
    },
    variants: [
      {
        props: { variant: "subtle" },
        style: ({ theme }) => ({
          color: theme.palette.text.primary,
          backgroundColor: "#00000014",
          "&:hover": {
            backgroundColor: "#0000001C"
          }
        })
      },
      {
        props: { variant: "serious" },
        style: () => ({
          color: basicColours.mono.white,
          backgroundColor: mainColours.background.error.primary,
          "&:hover": {
            backgroundColor: mainColours.background.error.hover
          }
        })
      }
    ]
  }
};
