import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useSelector } from "react-redux";
import { getRecentSiteSearches } from "../../redux/selectors/homepageSelectors";
import { navigateToRecentSite } from "../drawer/mainCustomDrawerSlice";
import { RecentSearchType } from "./homepageSlice";
import { useAppDispatch } from "../../store";
import { useNavigate } from "react-router-dom";

const RecentSearches = () => {
  const dispatch = useAppDispatch();
  const recentSiteSearches = useSelector(getRecentSiteSearches);
  const navigate = useNavigate();

  const onRecentSiteSearchClick = (recentSelectedSite: RecentSearchType) => {
    dispatch(navigateToRecentSite(recentSelectedSite));
    navigate(`/site/${recentSelectedSite.siteId}`);
  };

  return (
    <Stack
      sx={{
        flexDirection: "column",
        gap: 1
      }}
      aria-label="recent fulfillment center searches section"
    >
      <Typography variant="h6" fontWeight={400} color="gray.main">
        Select recently viewed project:
      </Typography>
      <Stack
        sx={{
          flexDirection: "column",
          gap: "5px",
          ml: 1
        }}
      >
        {recentSiteSearches.length ? (
          recentSiteSearches.map((search, index) => {
            const { siteName, clientName, siteId, clientId } = search;
            return (
              <Link
                variant="subtitle1"
                alignSelf="flex-start"
                component="button"
                underline="hover"
                aria-label="fulfillment center link"
                key={`${clientName}-${siteName}-${index}`}
                onClick={() =>
                  onRecentSiteSearchClick({
                    siteId,
                    siteName,
                    clientName,
                    clientId
                  })
                }
              >{`${clientName} > ${siteName}`}</Link>
            );
          })
        ) : (
          <Typography color="text.secondary">
            No recent viewed projects found.
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default RecentSearches;
