import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { updateUserMessagesList } from "../messagePopper/messagePopperSlice";
import { useAppDispatch } from "../../store";

const CopyToClipboardButton = (props: { value: string }) => {
  const { value } = props;
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(
      updateUserMessagesList({
        message: "Copied to clipboard!",
        type: "info"
      })
    );
    navigator.clipboard.writeText(value);
  };

  return (
    <IconButton
      aria-label="copy-to-clipboard-btn"
      data-testid="copy-to-clipboard-btn"
      onClick={handleClick}
      sx={{
        height: 25,
        width: 25
      }}
    >
      <ContentCopyIcon
        sx={{
          color: "gray.main"
        }}
      />
    </IconButton>
  );
};

export default CopyToClipboardButton;
