import { TextField } from "@mui/material";

type TextInputPropsType = {
  inputValue: string | number;
  readOnly?: boolean;
  isDisabled?: boolean;
  required: boolean;
  dataType?: string;
  onInputChange?: (value: string | number) => void;
  isPartOfPendingChangesList?: boolean;
  min?: number | null;
  max?: number | null;
  textLengthLimit?: number | null;
  setValidationError?: (customValidationError: string) => void;
  inputWidth?: number;
  errorMessage?: string;
};

const TextInput = (props: TextInputPropsType) => {
  const {
    inputValue,
    readOnly = false,
    required,
    dataType,
    isDisabled = false,
    onInputChange,
    isPartOfPendingChangesList = false,
    min,
    max,
    textLengthLimit,
    setValidationError,
    inputWidth,
    errorMessage
  } = props;
  const inputType = dataType === "int" ? "number" : "text";
  const isNumberType = inputType === "number";

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (readOnly || !onInputChange) return;

    const inputValue = isNumberType ? Number(e.target.value) : e.target.value;

    if (
      isNumberType &&
      min !== undefined &&
      min !== null &&
      max !== undefined &&
      max !== null
    ) {
      const isWithinBounds =
        Number(inputValue) >= min && Number(inputValue) <= max;

      if (isWithinBounds) {
        onInputChange(inputValue);
      } else if (setValidationError) {
        setValidationError(`Value needs to be between ${min} and ${max}`);
      }
    } else if (
      typeof inputValue === "string" &&
      textLengthLimit !== null &&
      textLengthLimit !== undefined
    ) {
      if (inputValue.length <= textLengthLimit) {
        onInputChange(inputValue);
      } else if (setValidationError) {
        setValidationError(
          `Value cannot exceed ${textLengthLimit} characters.`
        );
      }
    } else {
      onInputChange(inputValue);
    }
  };

  return (
    <TextField
      value={inputValue}
      type={inputType}
      required={required}
      multiline={!isNumberType}
      error={!!errorMessage}
      helperText={errorMessage}
      InputProps={{
        inputProps: {
          isPartOfPendingChangesList: isPartOfPendingChangesList || false,
          "aria-label": "number-text-input-field"
        },
        readOnly,
        disabled: isDisabled,
        tabIndex: readOnly ? -1 : 0
      }}
      variant="outlined"
      sx={{
        width: inputWidth || 230,
        height: inputType === "text" ? "auto" : 45
      }}
      onChange={handleInputChange}
    />
  );
};

export default TextInput;
