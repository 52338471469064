import { Stack, SxProps, Typography } from "@mui/material";
import ErrorOutlineIcon from "@locaisolutions/icons/dist/icons24px/Error24Px";
import RetryIcon from "@locaisolutions/icons/dist/icons20px/Retry20Px";
import { basicColours, mainColours } from "../lib/colors";
import { Theme } from "@emotion/react";
import CustomButton from "./CustomButton";

type ErrorPanelProps = {
  errorMessage: string;
  sx?: SxProps<Theme>;
  size?: "small" | "large";
  onRetryCb?: () => void | Promise<void>;
};

const ErrorPanel = ({
  errorMessage,
  sx,
  size = "large",
  onRetryCb
}: ErrorPanelProps) => {
  const normalizedSx: SxProps<Theme> = Array.isArray(sx) ? sx : [sx];
  const iconSize = size === "large" ? 35 : 25;
  return (
    <Stack
      p={size === "large" ? 4 : 1}
      m={size === "large" ? 3 : 1}
      sx={[
        (theme) => ({
          border: `3px solid ${theme.palette.error.dark}`,
          borderRadius: "6px",
          backgroundColor: theme.palette.error.light,
          minHeight: size === "large" ? "120px" : "90px"
        }),
        ...normalizedSx
      ]}
      flexDirection="column"
      gap={size === "large" ? 3 : 1}
      justifyContent="center"
      alignItems="center"
    >
      <Stack flexDirection="row" gap={1} alignItems="center">
        <ErrorOutlineIcon
          width={iconSize}
          height={iconSize}
          fill={mainColours.text.error}
        />
        <Typography
          variant={size === "large" ? "h5" : "subtitle1"}
          color={mainColours.text.error}
          fontWeight="500"
        >
          Error
        </Typography>
      </Stack>
      <Typography
        variant={size === "large" ? "h6" : "subtitle2"}
        color={mainColours.text.error}
      >
        {errorMessage}
      </Typography>
      {!!onRetryCb && (
        <CustomButton
          onClick={() => {
            onRetryCb();
          }}
          variant="contained"
          sx={{
            padding: size === "large" ? "25px 35px" : "10px 20px",
            maxHeight: 50,
            fontSize: size === "large" ? "1.25rem" : "0.9rem"
          }}
          startIcon={
            <RetryIcon
              fill={basicColours.mono.white}
              style={{
                width: iconSize - 5,
                height: iconSize - 5
              }}
            />
          }
        >
          Retry
        </CustomButton>
      )}
    </Stack>
  );
};

export default ErrorPanel;
