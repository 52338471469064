import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { checkUserLoggedInStatus } from "./redux/selectors/userSelectors";
import { useAuth0 } from "@auth0/auth0-react";
import { PropsWithChildren, useEffect } from "react";

const PrivateRoute = ({ children }: PropsWithChildren) => {
  const isUserLoggedIn = useSelector(checkUserLoggedInStatus);
  const { logout, isAuthenticated } = useAuth0();

  useEffect(() => {
    //log out user with auth0 if user has log out of the app
    if (!isUserLoggedIn && isAuthenticated) {
      logout({
        logoutParams: {
          returnTo: `${window.location.origin}/login`
        }
      });
    }
  }, [isAuthenticated, isUserLoggedIn]);

  if (!isUserLoggedIn) return <Navigate to="/login" replace />;

  return <>{children}</>;
};

export default PrivateRoute;
