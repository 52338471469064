import { useSelector } from "react-redux";
import { QueryStatus } from "@reduxjs/toolkit/query";
import Collapse from "@mui/material/Collapse";
import ChevronLeftIcon from "@locaisolutions/icons/dist/icons24px/ArrowLeft24Px";
import ChevronRightIcon from "@locaisolutions/icons/dist/icons24px/ArrowRight24Px";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { useGetDeploymentsStatusQuery } from "../../redux/api/site";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";
import DeploymentEventsStatusContent from "./DeploymentEventsStatusContent";
import { useAppDispatch } from "../../store";
import { updateUserMessagesList } from "../messagePopper/messagePopperSlice";
import { getPendingConfigurationItemsList } from "../../redux/selectors/siteSelectors";

const MainContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: { isDeploymentEventsDrawerOpen: boolean };
}>(({ extraProps, theme }) => ({
  padding: 10,
  borderRadius: "6px",
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  cursor: extraProps.isDeploymentEventsDrawerOpen ? "cursor" : "pointer",
  overflow: "hidden"
}));

const DeploymentEventsDrawerTypeTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: { isDeploymentEventsDrawerOpen: boolean };
}>(({ extraProps }) => ({
  transform: extraProps.isDeploymentEventsDrawerOpen ? "none" : "rotate(90deg)",
  transformOrigin: extraProps.isDeploymentEventsDrawerOpen ? "left" : "center",
  margin: extraProps.isDeploymentEventsDrawerOpen ? "0px 10px" : "15px",
  whiteSpace: extraProps.isDeploymentEventsDrawerOpen ? "normal" : "nowrap",
  textAlign: "center",
  fontSize: "1.2rem"
}));

const DeploymentEventsDrawerContent = styled(Collapse)(() => ({
  transition: "450ms cubic-bezier(0.23, 1, 0.32, 1) 2ms",
  "& .MuiCollapse-wrapper": {
    justifyContent: "center"
  },
  "& .MuiCollapse-wrapperInner": {
    width: "285px",
    height: "98%"
  },
  height: "95%"
}));

type DeploymentEventsStatusDrawerType = {
  isDeploymentEventsDrawerOpen: boolean;
  updateDeploymentsDrawerStatus: (isDrawerOpen: boolean) => void;
};

function DeploymentEventsStatusDrawer(props: DeploymentEventsStatusDrawerType) {
  const { isDeploymentEventsDrawerOpen, updateDeploymentsDrawerStatus } = props;
  const selectedSiteDrawerData = useSelector(getSelectedDrawersData).site;
  const dispatch = useAppDispatch();
  const pendingConfigurationChangesList = useSelector(
    getPendingConfigurationItemsList
  );
  const arePendingChangesRendered = !!Object.keys(
    pendingConfigurationChangesList
  ).length;
  const { data, refetch, status } = useGetDeploymentsStatusQuery(
    {
      siteId: selectedSiteDrawerData?.id || ""
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !selectedSiteDrawerData?.id
    }
  );
  const siteDeploymentEventsStatus = (data ||
    {}) as SiteDeploymentsStatusResponse;

  const refetchDeploymentStatus = async () => {
    try {
      const { isSuccess } = await refetch();
      if (isSuccess) {
        dispatch(
          updateUserMessagesList({
            message: "Fetched latest deployment changes successfully!",
            type: "info"
          })
        );
      }
    } catch (err) {
      dispatch(
        updateUserMessagesList({
          message: "Something went wrong fetching latest deployment changes.",
          type: "info"
        })
      );
    }
  };

  return (
    <MainContainer
      extraProps={{
        isDeploymentEventsDrawerOpen
      }}
      onClick={() =>
        !isDeploymentEventsDrawerOpen &&
        updateDeploymentsDrawerStatus(!isDeploymentEventsDrawerOpen)
      }
      sx={{
        gridColumn: arePendingChangesRendered ? "4/5" : "3/4"
      }}
      aria-label="deployment-status-drawer-container"
    >
      <Stack
        flexDirection="column"
        style={{
          height: !isDeploymentEventsDrawerOpen ? "100%" : "auto",
          cursor: isDeploymentEventsDrawerOpen ? "pointer" : "cursor"
        }}
        aria-label="deployment-status-drawer-header"
        onClick={() =>
          isDeploymentEventsDrawerOpen &&
          updateDeploymentsDrawerStatus(!isDeploymentEventsDrawerOpen)
        }
      >
        <Stack
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
          alignItems={isDeploymentEventsDrawerOpen ? "center" : "flex-start"}
          marginTop={isDeploymentEventsDrawerOpen ? "10px" : 0}
        >
          {isDeploymentEventsDrawerOpen ? (
            <ChevronLeftIcon
              aria-label="close-content-arrow-icon"
              width={16}
              height={16}
            />
          ) : (
            <ChevronRightIcon
              aria-label="open-content-arrow-icon"
              width={16}
              height={16}
            />
          )}
          <Box
            sx={{
              width: isDeploymentEventsDrawerOpen ? "90%" : "100%"
            }}
          >
            <DeploymentEventsDrawerTypeTitle
              extraProps={{
                isDeploymentEventsDrawerOpen
              }}
            >
              {`${selectedSiteDrawerData?.name} Status`}
            </DeploymentEventsDrawerTypeTitle>
          </Box>
        </Stack>
        {isDeploymentEventsDrawerOpen && <Divider sx={{ margin: 1 }} />}
      </Stack>
      <DeploymentEventsDrawerContent
        in={isDeploymentEventsDrawerOpen}
        orientation="horizontal"
      >
        <DeploymentEventsStatusContent
          {...siteDeploymentEventsStatus}
          isDeploymentEventsDrawerOpen={isDeploymentEventsDrawerOpen}
          refetchDeploymentStatus={refetchDeploymentStatus}
          isDeploymentStatusLoading={status === QueryStatus.pending}
        />
      </DeploymentEventsDrawerContent>
    </MainContainer>
  );
}

export default DeploymentEventsStatusDrawer;
