import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";

import CheckCircleOutlineIcon from "@locaisolutions/icons/dist/icons24px/CheckmarkCircle24Px";
import ErrorOutlineIcon from "@locaisolutions/icons/dist/icons24px/CloseCircle24Px";
import CloseIcon from "@locaisolutions/icons/dist/icons24px/Close24Px";

import { getUserMessagesList } from "../../redux/selectors/messagePopper";
import { removeUserMessage } from "./messagePopperSlice";
import { useAppDispatch } from "../../store";
import { basicColours } from "../../lib/colors";

const UserMessageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "extraProps"
})<{
  extraProps: {
    isInfoMessage: boolean;
  };
}>(({ extraProps, theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  borderRadius: "10px",
  minWidth: 300,
  backgroundColor: extraProps.isInfoMessage
    ? theme.palette.success.main
    : theme.palette.error.main,
  color: basicColours.mono.white,
  padding: "20px 30px"
}));

const UserMessage = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "16px"
}));

const CloseIconButton = styled(IconButton)(() => ({
  position: "relative",
  bottom: 15,
  left: 25
}));

export const MessagePopper = () => {
  const userMessagesList = useSelector(getUserMessagesList);
  const dispatch = useAppDispatch();

  return (
    <Box>
      {userMessagesList.map((userMessage) => {
        const { id, type, duration, message } = userMessage;
        const isInfoMessage = type === "info";
        return (
          <Snackbar
            open={!!message}
            key={`user-message-${id}`}
            autoHideDuration={duration}
            onClose={() => dispatch(removeUserMessage(id))}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <UserMessageContainer
              extraProps={{ isInfoMessage }}
              data-testid="user-message-container"
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {isInfoMessage ? (
                  <CheckCircleOutlineIcon
                    style={{
                      width: 30,
                      height: 30,
                      marginRight: "10px",
                      fill: "#fff"
                    }}
                  />
                ) : (
                  <ErrorOutlineIcon
                    style={{
                      width: 30,
                      height: 30,
                      marginRight: "10px",
                      fill: "#fff"
                    }}
                  />
                )}
                <UserMessage data-testid="user-message">{message}</UserMessage>
              </Box>
              <CloseIconButton
                onClick={() => {
                  dispatch(removeUserMessage(id));
                }}
              >
                <CloseIcon
                  style={{
                    fill: "#fff",
                    width: 15,
                    height: 15
                  }}
                />
              </CloseIconButton>
            </UserMessageContainer>
          </Snackbar>
        );
      })}
    </Box>
  );
};
