import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { useDispatch } from "react-redux";

import mainCustomDrawerReducer from "./components/drawer/mainCustomDrawerSlice";
import siteReducer, { siteSliceTransform } from "./components/site/siteSlice";
import userReducer from "./components/login/userSlice";
import homePageReducer from "./components/home/homepageSlice";
import messagePopperReducer from "./components/messagePopper/messagePopperSlice";
import { clientConfigApi } from "./redux/api";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["mainCustomDrawer", "api", "messagePopper"],
  transforms: [siteSliceTransform]
};

const mainReducer = combineReducers({
  mainCustomDrawer: mainCustomDrawerReducer,
  homePage: homePageReducer,
  site: siteReducer,
  user: userReducer,
  messagePopper: messagePopperReducer,
  [clientConfigApi.reducerPath]: clientConfigApi.reducer
});

export type RootState = ReturnType<typeof mainReducer>;

export type AppSelector<R> = (state: RootState) => R;
export const selectState = (state: RootState) => state;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

const persistedReducer = persistReducer<RootState>(persistConfig, mainReducer);

export const createReduxStore = () => {
  const store = configureStore({
    reducer: persistedReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }).concat(clientConfigApi.middleware)
  });
  const persistor = persistStore(store);

  return { store, persistor };
};

const { store, persistor } = createReduxStore();

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export { store, persistor };
