import { useSelector } from "react-redux";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";

import PendingChangeIcon from "@locaisolutions/icons/dist/icons20px/Send20Px";
import { SelectedSingleDrawerDataType } from "./mainCustomDrawerSlice";
import Loading from "../Loading";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";
import { getSavedPendingSiteEditsBySite } from "../../redux/selectors/siteSelectors";
import { Stack, Box } from "@mui/material";
import { mainColours } from "../../lib/colors";

type CustomDrawerContentProps = {
  isDrawerDataLoading: boolean;
  onDrawerMenuBtnClick: (
    selectedDrawerOption: SelectedSingleDrawerDataType,
    parentDetails?: SelectedSingleDrawerDataType | null
  ) => void;
  drawerContentData: CustomDrawerContentType[];
  drawerType: DrawerType;
};

const MainContainer = styled(Box)(() => ({
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  height: "98%",
  width: "100%",
  padding: "10px 5px"
}));

function CustomDrawerContent(props: CustomDrawerContentProps) {
  const {
    onDrawerMenuBtnClick,
    drawerContentData,
    isDrawerDataLoading,
    drawerType
  } = props;
  const selectedDrawerData = useSelector(getSelectedDrawersData);
  const pendingChangesForAllSites = useSelector(getSavedPendingSiteEditsBySite);

  const onDrawerItemClickBtn = (
    name: string,
    salesforceId: string | null,
    id: string
  ) => {
    const selectedClientDrawerDetails =
      drawerType === "site" ? selectedDrawerData.client : undefined;
    onDrawerMenuBtnClick(
      {
        name,
        id,
        salesforceId: salesforceId || ""
      },
      selectedClientDrawerDetails
    );
  };

  return (
    <MainContainer data-testid={`drawer-content-${drawerType}`}>
      {isDrawerDataLoading ? (
        <Loading numberOfRows={5} />
      ) : (
        <Stack flexDirection="column" gap={1} margin={"auto"}>
          {drawerContentData.map((drawer, index) => {
            const { name, salesforceId, id } = drawer;
            const hasPendingChangesForSelectedSite =
              drawerType === "site" &&
              Object.values(pendingChangesForAllSites?.[id] || {}).length > 0;
            return (
              <ListItemButton
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
                data-testid={`${drawerType}-drawer-content-item`}
                key={`${name}-${id}${index}`}
                onClick={() => onDrawerItemClickBtn(name, salesforceId, id)}
              >
                <ListItemText primary={name} />
                {hasPendingChangesForSelectedSite && (
                  <PendingChangeIcon
                    style={{
                      fill: mainColours.icon.success,
                      position: "sticky"
                    }}
                  />
                )}
              </ListItemButton>
            );
          })}
        </Stack>
      )}
    </MainContainer>
  );
}

export default CustomDrawerContent;
