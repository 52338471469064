import { Components, Theme } from "@mui/material";
import { basicColours, mainColours } from "./colors";

export const muiOutlineInput: Components<Omit<Theme, "components">> = {
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        return {
          borderRadius: "6px",
          padding: "7px 12px",
          border: "none",
          minHeight: 48,
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
          ...(ownerState.disabled && {
            "& .MuiOutlinedInput-input": {
              color: theme.palette.text.primary,
              cursor: "not-allowed"
            }
          }),
          ...(ownerState.error && {
            backgroundColor: theme.palette.error.light
          }),
          ...(!!ownerState?.inputProps?.isPartOfPendingChangesList && {
            backgroundColor: theme.palette.success.light
          }),
          ...(ownerState.readOnly && {
            pointerEvents: "none"
          }),
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px",
            borderColor: mainColours.border.secondary,
            ...(ownerState.error && {
              borderWidth: "2px",
              borderColor: theme.palette.error.dark
            }),
            ...(!!ownerState?.inputProps?.isPartOfPendingChangesList && {
              borderWidth: "2px",
              borderColor: theme.palette.success.dark
            }),
            "&:hover": {
              borderColor: basicColours.mono[300]
            }
          },
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: basicColours.mono[50]
          }
        };
      }
    }
  }
};
