import { Components, Theme } from "@mui/material";
import { mainColours } from "./colors";

export const muiLink: Components<Omit<Theme, "components">> = {
  MuiLink: {
    defaultProps: {
      style: {
        color: mainColours.text.secondary,
        fontFamily:
          'CircularXX, "Helvetica Neue", Arial, Helvetica, sans-serif',
        borderColor: mainColours.border.secondary
      }
    }
  }
};
