import { Box, Stack, Typography, styled } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import SitesSearchBar from "./SitesSearchBar";
import RecentSearches from "./RecentSearches";

import homePageImg from "../../assets/images/homePage.avif";
import { mobileWidth } from "../../lib/theme";
import { useSelector } from "react-redux";
import { getCurrentOpenDrawerType } from "../../redux/selectors/drawerSelectors";

const MainContainer = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "2fr 3fr",
  height: "100%",
  width: "100%"
}));

const HomePage = () => {
  const isMobile = useMediaQuery(mobileWidth);
  const currentSelectedDrawerType = useSelector(getCurrentOpenDrawerType);
  const isSiteDrawer = currentSelectedDrawerType === "site";

  if (isSiteDrawer) return <></>;

  return (
    <MainContainer>
      <Stack
        sx={{
          flexDirection: "column",
          gap: 5,
          ml: 8,
          mb: 5,
          justifyContent: "center"
        }}
      >
        <Stack flexDirection={"column"}>
          <Typography variant="h6" fontWeight={300}>
            Welcome to
          </Typography>
          <Typography fontWeight={500} variant="h4">
            Fulfillment Center Configuration
          </Typography>
        </Stack>

        <Stack
          sx={{
            flexDirection: "column",
            gap: 1
          }}
          aria-label="fulfillment center search section"
        >
          <Typography variant="subtitle1" ml={"3px"}>
            Choose and navigate to selected site:
          </Typography>
          <SitesSearchBar />
        </Stack>
        <RecentSearches />
      </Stack>

      {!isMobile && (
        <img
          src={homePageImg}
          alt="homePage-img"
          style={{
            objectFit: "cover",
            objectPosition: "center",
            height: "100%",
            width: "100%"
          }}
        />
      )}
    </MainContainer>
  );
};

export default HomePage;
