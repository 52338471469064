import { clientConfigApi } from ".";
import {
  formatGetSiteDeploymentStatusResponse,
  getConfigurationTreeAndConfigurationMenu,
  getRequiredDuplicatesiteFields
} from "../../lib/siteHelpers";

export type GetDeploymentsStatusResponse = SiteDeploymentStatus[];

export const siteApi = clientConfigApi.injectEndpoints({
  endpoints: (builder) => ({
    getSitesListByPartner: builder.query<
      GetSitesListResponse,
      { partnerId: string }
    >({
      query: (data) => {
        const { partnerId } = data;
        return {
          url: `/api/Sites?partnerId=${partnerId}`,
          method: "GET"
        };
      },
      //@ts-ignore
      transformResponse: (
        response: GetSitesListResponse,
        _: unknown
      ): CustomDrawerContentType[] => {
        return response.map((siteData) => ({
          id: siteData.siteId,
          name: siteData.name,
          salesforceId: siteData.salesforceId
        }));
      },
      providesTags: ["site"]
    }),
    getSitesListByClient: builder.query<
      GetSitesListResponse,
      { clientId: string }
    >({
      query: (data) => {
        const { clientId } = data;
        return {
          url: `/api/Sites?clientId=${clientId}`,
          method: "GET"
        };
      },
      providesTags: ["site"]
    }),
    getSitesList: builder.query<GetSitesListResponse, undefined>({
      query: () => {
        return {
          url: `/api/Sites`,
          method: "GET"
        };
      },
      //@ts-ignore
      transformResponse: (
        response: GetSitesListResponse,
        _: unknown
      ): CustomDrawerContentType[] => {
        return response.map((siteData) => ({
          id: siteData.siteId,
          name: siteData.name,
          salesforceId: siteData.salesforceId
        }));
      },
      providesTags: ["site"]
    }),
    getSitesListPreview: builder.query<GetSitesListPreviewResponse, undefined>({
      query: () => {
        return {
          url: `/api/Sites/SiteList`,
          method: "GET"
        };
      },
      providesTags: []
    }),
    getSiteConfigurationsList: builder.query<
      SiteConfigurationTypeResponse,
      { siteId: string }
    >({
      query: (data) => {
        const { siteId } = data;
        return {
          url: `/api/Configurations?site_id=${siteId}`,
          method: "GET"
        };
      },
      //@ts-ignore
      transformResponse: (
        response: SiteConfigurationTypeResponse,
        _: unknown
      ): SiteConfigurationResponseType => {
        const formattedResponse =
          getConfigurationTreeAndConfigurationMenu(response);

        return formattedResponse;
      },
      providesTags: ["site"]
    }),
    getConfigurationItemsListByConfiguration: builder.query<
      ConfigurationItems,
      { configurationId: string }
    >({
      query: (data) => {
        const { configurationId } = data;
        return {
          url: `/api/ConfigurationItems?configuration_id=${configurationId}`,
          method: "GET"
        };
      },
      providesTags: ["site"]
    }),
    getDuplicateSiteDetails: builder.query<
      GetDuplicateSiteResponse,
      { siteId: string; envIdsList: string[] }
    >({
      query: (data) => {
        const { siteId, envIdsList } = data;
        return {
          url: `/api/DuplicateSite`,
          body: {
            site_id: siteId,
            environment_ids: envIdsList
          },
          method: "POST"
        };
      },
      //@ts-ignore
      transformResponse: (
        response: GetDuplicateSiteResponse,
        _: unknown
      ): FormattedDuplicateSiteResponse => {
        const formattedDuplicateSiteMenu =
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          getConfigurationTreeAndConfigurationMenu(response.configurationTypes);
        const additionaDuplicateSiteInformation =
          getRequiredDuplicatesiteFields(
            response.configurationTypes,
            response.requiredConfigItemIds
          );
        const {
          requiredDuplicateSiteConfigurations,
          allSiteConfigurationsByConfigurationId
        } = additionaDuplicateSiteInformation;
        const formattedReponse: FormattedDuplicateSiteResponse = {
          ...response,
          requiredSiteConfigurationItems: requiredDuplicateSiteConfigurations,
          allSiteConfigurationsByConfigurationId,
          siteConfigurationsByMenuType: formattedDuplicateSiteMenu
        };

        return formattedReponse;
      },
      providesTags: []
    }),
    postDuplicateOrCreateSite: builder.query<
      SubmitDuplicateOrCreateSiteResponse,
      SubmitDuplicateOrCreateSiteBody
    >({
      query: (data) => {
        return {
          url: `/api/Sites`,
          method: "POST",
          body: data
        };
      },
      providesTags: []
    }),
    getDeploymentsStatus: builder.query<
      GetDeploymentsStatusResponse,
      { siteId: string }
    >({
      query: (data) => {
        const { siteId } = data;
        return {
          url: `/api/Deployments?site_id=${siteId}`,
          method: "GET"
        };
      },
      //@ts-ignore
      transformResponse: (
        response: GetDeploymentsStatusResponse
      ): SiteDeploymentsStatusResponse => {
        const formattedResponse =
          formatGetSiteDeploymentStatusResponse(response);

        return formattedResponse;
      },
      providesTags: []
    }),
    editSiteDetails: builder.query<
      null,
      {
        editFields: EditSiteConfigurationItemsBody;
        siteId: string;
      }
    >({
      query: (data) => {
        const { editFields, siteId } = data;
        return {
          url: `/api/Sites/${siteId}`,
          method: "PUT",
          body: editFields
        };
      },
      providesTags: []
    })
  }),
  overrideExisting: true
});

export const {
  useGetSitesListByPartnerQuery,
  useGetSitesListQuery,
  useGetSitesListByClientQuery,
  useLazyGetSitesListByClientQuery,
  useGetSitesListPreviewQuery,
  useLazyGetSitesListPreviewQuery,
  useGetSiteConfigurationsListQuery,
  useGetConfigurationItemsListByConfigurationQuery,
  useGetDuplicateSiteDetailsQuery,
  usePostDuplicateOrCreateSiteQuery,
  useLazyPostDuplicateOrCreateSiteQuery,
  useGetDeploymentsStatusQuery,
  useLazyEditSiteDetailsQuery
} = siteApi;

export default siteApi;
