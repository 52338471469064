import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent
} from "@mui/material";

type MultiselectInputPropsType = {
  value: string[];
  multiselectValues: string[];
  readOnly?: boolean;
  isDisabled?: boolean;
  onInputChange?: (value: string | string[]) => void;
  isPartOfPendingChangesList?: boolean;
  inputWidth?: number;
  errorMessage?: string;
};

const MultiselectInput = (props: MultiselectInputPropsType) => {
  const {
    value,
    multiselectValues,
    isDisabled = false,
    readOnly,
    onInputChange,
    isPartOfPendingChangesList,
    inputWidth,
    errorMessage
  } = props;

  const handleDelete = (itemValueToDelete: string) => {
    if (readOnly) return;
    const newMultiSelectValue = value.filter((el) => el !== itemValueToDelete);

    if (onInputChange) onInputChange(newMultiSelectValue);
  };

  const handleChange = (e: SelectChangeEvent<string[]>) => {
    if (readOnly) return;
    const selectedConfigItem = e.target.value as string[];
    if (onInputChange) onInputChange(selectedConfigItem);
  };

  return (
    <FormControl
      variant="outlined"
      error={!!errorMessage}
      sx={{ width: inputWidth || 230 }}
    >
      <Select
        multiple
        value={value}
        readOnly={readOnly}
        error={!!errorMessage}
        variant="outlined"
        style={{ padding: 0 }}
        disabled={isDisabled}
        inputProps={{
          isPartOfPendingChangesList: isPartOfPendingChangesList || false,
          "aria-readonly": readOnly
        }}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" />}
        renderValue={(selected: string[]) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {selected.map((value: string, i) => (
              <Chip
                key={`multiselect-chip-${value}-${i}`}
                data-testid={`multiselect-chip-btn`}
                label={value}
                onDelete={() => {
                  if (isDisabled || readOnly) return;

                  handleDelete(value);
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                aria-readonly={readOnly}
                disabled={isDisabled}
                disableRipple
              />
            ))}
          </Box>
        )}
      >
        {multiselectValues.map((value: string, i) => (
          <MenuItem key={`multiselect-value-${value}-${i}`} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};
export default MultiselectInput;
