import { Components, Theme } from "@mui/material";

export const muiAppBar: Components<Omit<Theme, "components">> = {
  MuiAppBar: {
    defaultProps: {
      style: {
        backgroundColor: "#f0f0f0",
        color: "rgba(0, 0, 0, 0.87)",
        borderBottom: "1px solid rgba(0,0,0,0.2)",
        minHeight: 50
      },
      elevation: 0
    }
  }
};
