import { Components, Theme } from "@mui/material";
import { basicColours } from "./colors";

export const muiSwitch: Components<Omit<Theme, "components">> = {
  MuiSwitch: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: "54px",
        height: "35px",
        padding: 0,
        margin: "8px auto",
        "& .MuiSwitch-switchBase": {
          padding: 0,
          margin: "5px",
          transform: "translateX(1px)",
          "&.Mui-disabled + .MuiSwitch-track": {
            backgroundColor: basicColours.mono[300],
            opacity: 0.4
          },
          "&.Mui-disabled .MuiSwitch-thumb": {
            opacity: 0.9,
            boxShadow: "-3px 2px 5px 0px rgba(0,0,0,.10)"
          },
          "&.Mui-checked": {
            transform: "translateX(18px)",
            color: theme.palette.primary.main,
            "& + .MuiSwitch-track": {
              backgroundColor: theme.palette.primary.main,
              opacity: 1
            },
            ".MuiSwitch-thumb": {
              boxShadow: "2px 3px 5px 0px rgba(0,0,0,.45)"
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              boxShadow: "2px 3px 5px 0px rgba(0,0,0,.15)"
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.2
            }
          }
        },
        "& .MuiSwitch-thumb": {
          boxSizing: "border-box",
          width: "25px",
          height: "25px",
          boxShadow: "-3px 2px 5px 0px rgba(0,0,0,.25)",
          backgroundColor: "#fff"
        },
        "& .MuiSwitch-track": {
          borderRadius: "18px",
          backgroundColor: basicColours.mono[500]
        }
      })
    }
  }
};
