import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, FormHelperText } from "@mui/material";

type SelectInputPropsType = {
  value: string;
  selectValues: string[];
  readOnly?: boolean;
  isDisabled?: boolean;
  onInputChange?: (value: string | string[]) => void;
  isPartOfPendingChangesList?: boolean;
  inputWidth?: number;
  errorMessage?: string;
};

const SelectInput = (props: SelectInputPropsType) => {
  const {
    value,
    selectValues,
    isDisabled,
    readOnly,
    onInputChange,
    isPartOfPendingChangesList,
    inputWidth,
    errorMessage
  } = props;

  return (
    <FormControl
      variant="outlined"
      error={!!errorMessage}
      sx={{ width: inputWidth || 230 }}
    >
      <Select
        value={value}
        onChange={(e) => {
          if (!readOnly && onInputChange) {
            onInputChange(e.target.value);
          }
        }}
        disabled={isDisabled}
        readOnly={readOnly}
        error={!!errorMessage}
        variant="outlined"
        inputProps={{
          isPartOfPendingChangesList: isPartOfPendingChangesList || false,
          "aria-readonly": readOnly
        }}
      >
        {selectValues.map((value: string, index: number) => (
          <MenuItem key={`${value}-${index}`} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};
export default SelectInput;
