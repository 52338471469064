import { Button, ButtonProps } from "@mui/material";
import CustomLoadingIcon from "@locaisolutions/icons/dist/icons20px/MoreHorizontal20Px";
import { basicColours } from "../lib/colors";

interface CustomButtonrProps extends ButtonProps {
  loading?: boolean;
}
const CustomButton = (props: CustomButtonrProps) => {
  const { loading = false, children } = props;
  return (
    <Button {...props} disabled={props.disabled || loading}>
      {loading ? (
        <CustomLoadingIcon
          width={20}
          height={20}
          fill={basicColours.mono.white}
          style={{ marginTop: 5 }}
        />
      ) : (
        children
      )}
    </Button>
  );
};

export default CustomButton;
