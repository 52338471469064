import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import MainCustomDrawer from "./drawer/MainCustomDrawer";
import Navbar from "./Navbar";

type CustomPageWrapperProps = {
  children?: React.ReactNode;
};
function CustomPageWrapper(props: CustomPageWrapperProps) {
  const { children } = props;
  return (
    <Box
      id="CustomPageWrapper"
      display="grid"
      height="100vh"
      gridTemplateColumns="minmax(120px, auto) 1fr"
    >
      <Navbar />
      <MainCustomDrawer />
      <Box component="main" sx={{ gridColumn: 2, overflow: "auto" }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

export default CustomPageWrapper;
