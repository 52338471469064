import { ChangeEvent } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import CustomButton from "../CustomButton";

export type OptionType = {
  label: string;
  id: string;
  additionalData?: unknown;
};

type SearchBarPropsType = {
  inputLabel?: string;
  inputPlaceholder?: string;
  inputValue: string;
  isEnterBtnDisabled?: boolean;
  options: OptionType[];
  onSelect: (site: OptionType | null) => void;
  onInputValueChange: (inputValue: string) => void;
  onEnterBtnClick?: () => void;
  autocompleteMatcher?: (option: OptionType, value: OptionType) => boolean;
};

const SearchBar = (props: SearchBarPropsType) => {
  const {
    options,
    inputLabel = "",
    inputPlaceholder = "",
    onSelect,
    onInputValueChange,
    inputValue,
    isEnterBtnDisabled = false,
    onEnterBtnClick,
    autocompleteMatcher
  } = props;

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: 1
      }}
    >
      <Autocomplete
        data-testid="searchbar-autocomplete"
        sx={{
          minWidth: 300,
          "& .MuiAutocomplete-inputRoot": {
            paddingRight: "0 !important"
          }
        }}
        size="small"
        autoHighlight
        isOptionEqualToValue={autocompleteMatcher}
        inputValue={inputValue}
        options={options}
        autoComplete
        loadingText="Loading"
        noOptionsText="No results"
        getOptionLabel={(option: OptionType) => option.label}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={`site-${option.id}-preview`}>
            {option.label}
          </Box>
        )}
        onChange={(_e: ChangeEvent<unknown>, option: OptionType | null) => {
          onSelect(option);
        }}
        onInputChange={(_e: ChangeEvent<unknown>, inputValue: string) => {
          onInputValueChange(inputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={inputLabel}
            type="text"
            placeholder={inputPlaceholder}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "6px"
              }
            }}
          />
        )}
      />
      {onEnterBtnClick && (
        <CustomButton
          aria-label="navigate to selected site"
          color="primary"
          onClick={onEnterBtnClick}
          disabled={isEnterBtnDisabled}
          sx={{ fontSize: "1rem" }}
        >
          Go
        </CustomButton>
      )}
    </Stack>
  );
};

export default SearchBar;
