import { SelectedSingleDrawerDataType } from "./mainCustomDrawerSlice";
import CustomDrawerContent from "./CustomDrawerContent";
import { useGetSitesListByClientQuery } from "../../redux/api/site";
import { getSelectedDrawersData } from "../../redux/selectors/drawerSelectors";
import { useSelector } from "react-redux";
import ErrorPanel from "../ErrorPanel";

type SitesDrawerContenProps = {
  onDrawerMenuBtnClick: (
    selectedDrawerOption: SelectedSingleDrawerDataType,
    parentDetails?: SelectedSingleDrawerDataType | null
  ) => void;
  drawerType: DrawerType;
};

function SitesDrawerContent(props: SitesDrawerContenProps) {
  const selectedClientDrawerData = useSelector(getSelectedDrawersData).client;
  const clientId = selectedClientDrawerData?.id || "";
  const {
    data = [],
    isFetching,
    isError,
    refetch
  } = useGetSitesListByClientQuery(
    {
      clientId
    },
    {
      skip: !clientId,
      refetchOnMountOrArgChange: true
    }
  );
  const shapedSiteData: CustomDrawerContentType[] = data.map((siteData) => ({
    id: siteData.siteId,
    name: siteData.name,
    salesforceId: siteData.salesforceId
  }));

  if (isError)
    return (
      <ErrorPanel
        errorMessage="Failed to retrieve sites list."
        size="small"
        sx={{
          textAlign: "center"
        }}
        onRetryCb={async () => {
          await refetch();
        }}
      />
    );

  return (
    <CustomDrawerContent
      {...props}
      isDataLoading={isFetching}
      drawerContentData={shapedSiteData}
    />
  );
}

export default SitesDrawerContent;
