import { useEffect } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import PendingChangeIcon from "@locaisolutions/icons/dist/icons20px/Send20Px";

import { setSelectedSiteSection } from "./siteSlice";
import {
  getPendingConfigurationItemsList,
  getSelectedSiteSection
} from "../../redux/selectors/siteSelectors";
import { useAppDispatch } from "../../store";
import { mainColours } from "../../lib/colors";

export const SectionGroupingMenuTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 700,
  whiteSpace: "nowrap",
  color: theme.palette.text.primary
}));

type SiteConfigurationProps = {
  sectionData: SiteConfigurationMenuItem;
  allConfigurationsMenuTypes: SiteConfigurationMenuGroupings;
  childGroupingHeader?: string;
  isInNewSiteCreatingMode: boolean;
};

function SiteConfiguration(props: SiteConfigurationProps) {
  const {
    sectionData,
    allConfigurationsMenuTypes,
    childGroupingHeader,
    isInNewSiteCreatingMode
  } = props;
  const dispatch = useAppDispatch();
  const selectedSiteSection = useSelector(getSelectedSiteSection);
  const pendingConfigurationChangesList = useSelector(
    getPendingConfigurationItemsList
  );
  const { configurationId, children, name: configurationName } = sectionData;
  const isSiteSectionSelected = selectedSiteSection.id === configurationId;

  const setSelectedSectionDetails = (
    configurationId: string,
    configurationName: string
  ): void => {
    dispatch(
      setSelectedSiteSection({
        configurationId,
        configurationName
      })
    );
  };

  const getIsSectionPartOfPendingListOfChanges = (
    configurationId: string
  ): boolean => {
    return !!Object.values(pendingConfigurationChangesList).find(
      (pendingChangeValue) => pendingChangeValue.sectionId === configurationId
    );
  };
  const clearSelectedConfigurationData = () => {
    setSelectedSectionDetails("", "");
  };

  const onClickBtn = () => {
    setSelectedSectionDetails(configurationId, configurationName);
  };

  useEffect(() => {
    return () => {
      clearSelectedConfigurationData();
    };
  }, []);

  const isSectionPartOfPendingListChanges =
    getIsSectionPartOfPendingListOfChanges(configurationId);

  return (
    <Stack flexDirection="column" gap={1}>
      {childGroupingHeader && (
        <Stack flexDirection="column" gap={0.5} ml={1}>
          <SectionGroupingMenuTitle
            data-testid="section-title"
            sx={{
              fontSize: "0.938rem"
            }}
          >
            {childGroupingHeader}
          </SectionGroupingMenuTitle>
          <Divider sx={{ borderBottomWidth: 1 }} />
        </Stack>
      )}
      <ListItemButton
        onClick={() => onClickBtn()}
        selected={isSiteSectionSelected}
        sx={{
          m: 0,
          p: "8px 10px"
        }}
      >
        <ListItemText
          data-testid="sub-section-title"
          primary={configurationName}
        />
        {isSectionPartOfPendingListChanges && (
          <Icon
            sx={{
              marginLeft: "0.5rem"
            }}
          >
            <PendingChangeIcon
              style={{
                height: 22,
                width: 22,
                fill: mainColours.icon.success
              }}
            />
          </Icon>
        )}
      </ListItemButton>
      <>
        {children.map(
          (currentChildSection: SiteConfigurationMenuItem, i, arr) => {
            const {
              configurationId,
              configurationTypeId: currentConfigurationGroupingId
            } = currentChildSection;

            const prevChildSection = i > 0 ? arr[i - 1] : null;
            const prevConfigurationGroupingId = prevChildSection
              ? prevChildSection.configurationTypeId
              : "";

            let childGroupingHeader = null;
            if (
              !prevChildSection ||
              (prevChildSection &&
                currentConfigurationGroupingId !== prevConfigurationGroupingId)
            ) {
              childGroupingHeader =
                allConfigurationsMenuTypes?.[currentConfigurationGroupingId];
            }

            return (
              <Stack
                flexDirection="column"
                key={`child-site-section-${configurationId}`}
                ml={1.5}
              >
                <SiteConfiguration
                  sectionData={currentChildSection}
                  allConfigurationsMenuTypes={allConfigurationsMenuTypes}
                  childGroupingHeader={
                    childGroupingHeader?.configurationTypeName
                  }
                  isInNewSiteCreatingMode={isInNewSiteCreatingMode}
                />
                {i === arr.length - 1 && (
                  <Divider sx={{ borderBottomWidth: 1, mt: 0.5 }} />
                )}
              </Stack>
            );
          }
        )}
      </>
    </Stack>
  );
}

export default SiteConfiguration;
